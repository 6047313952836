import clsx from 'clsx';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { useUserGroupsSelectorStyles } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/UsersGroupsSelector.styles';
import { UsersGroupsListItemProps } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  SelectUserOption,
  SelectUserGroupOption,
} from 'utils/types/selectInput.types';

export const UsersGroupsListItemSelectOnly = ({
  item,
  handleClick,
  selected,
  searchText,
  limitReached,
  isDisabled,
}: UsersGroupsListItemProps) => {
  const sharedStyles = useUserGroupsSelectorStyles();
  const intl = useIntl();
  const disabled = isDisabled || selected || limitReached;
  const handleOnClick = () => {
    if (disabled) return;
    handleClick(item);
  };

  return (
    <button
      className={clsx(sharedStyles.optionItem, {
        [sharedStyles.optionDisabled]: disabled,
      })}
      onClick={handleOnClick}
      aria-pressed={selected}
    >
      {item.hasOwnProperty('username') ? (
        <OptionAvatar
          disabledText={
            selected
              ? intl.formatMessage({
                  id: 'misc.alreadySelected',
                  defaultMessage: 'Already selected',
                })
              : ''
          }
          selected={selected}
          disabled={disabled}
          option={item as SelectUserOption}
          searchValue={searchText ?? ''}
        />
      ) : (
        <OptionGroupAvatar
          disabledText={
            selected
              ? intl.formatMessage({
                  id: 'misc.alreadySelected',
                  defaultMessage: 'Already selected',
                })
              : ''
          }
          selected={selected}
          disabled={disabled}
          option={item as SelectUserGroupOption}
          searchValue={searchText ?? ''}
          showUserCount
        />
      )}
    </button>
  );
};
