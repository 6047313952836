import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ArrowRightIcon, SyncIcon } from 'components/Icon';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { useUserGroupsSelectorStyles } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/UsersGroupsSelector.styles';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ExpandedUserList } from './components/ExpandedUserList';
import { useUsersGroupsListItemExpandableStyles } from './ExpandablePickerUserGroupItem.styles';
import { ExpandablePickerUserGroupItemProps } from './ExpandablePickerUserGroupItem.types';
import Popover from 'components/lib/Popover';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import {
  USERS_AND_GROUPS_SELECT_GROUP_ITEM_ARROW_TESTID,
  USERS_AND_GROUPS_SELECT_ITEM_GROUP_TYPE_TESTID,
} from 'utils/testIds';

/**
 * A single item that represents a user group on the left panel of UserAndGroupsExpandablePicker with
 * the logic to expand the right side panel.
 */
export const ExpandablePickerUserGroupItem = ({
  item,
  limitReached,
  onPopupOpen,
}: ExpandablePickerUserGroupItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const elementRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const sharedStyles = useUserGroupsSelectorStyles();
  const styles = useUsersGroupsListItemExpandableStyles();

  const {
    searchText,
    selectedItems,
    getPopupContainer,
  } = useUsersAndGroupsExpandablePickerContext();

  const isSelected = selectedItems.groups.get(item.id) !== undefined;

  const closeExpandedPanel = useCallback(() => {
    setIsOpen(false);
    onPopupOpen(false);
  }, [onPopupOpen]);

  useOutsideClick(
    popoverRef,
    closeExpandedPanel,
    undefined,
    undefined,
    elementRef.current
  );

  const { getScrollableParent } = useScrollableParent(elementRef);

  const onItemClick = () => {
    if (disabled) {
      return;
    }

    const newOpenState = !isOpen;
    setIsOpen(newOpenState);
    onPopupOpen(newOpenState);
  };

  useEffect(() => {
    // if expanded panel gets unmounted (for example, it was filtered with search on the main dropdown)
    // we should notify the parents that it's closed to avoid wrong state.
    return () => {
      closeExpandedPanel();
    };
  }, [closeExpandedPanel]);

  const disabled = limitReached;
  return (
    <Popover
      content={
        <div ref={popoverRef} onClick={e => e.stopPropagation()}>
          <ExpandedUserList userGroup={item} />
        </div>
      }
      getPopupContainer={getPopupContainer ?? getScrollableParent}
      overlayClassName={styles.popoverOverlay}
      trigger='click'
      visible={isOpen}
      align={{
        offset: [5, 0],
      }}
      placement={'rightTop'}
      destroyTooltipOnHide
    >
      <button
        key={`group-item-test-${item.id}`}
        ref={elementRef}
        className={clsx(sharedStyles.optionItem, {
          [sharedStyles.optionDisabled]: disabled,
          [styles.itemOpenedBackground]: isOpen,
        })}
        data-testid={USERS_AND_GROUPS_SELECT_ITEM_GROUP_TYPE_TESTID}
        onClick={onItemClick}
        aria-pressed={isOpen}
      >
        <OptionGroupAvatar
          disabledText={undefined}
          option={item}
          searchValue={searchText}
          disabled={disabled}
          showUserCount
        />
        {isSelected && <SyncIcon className={styles.syncIcon} size={16} />}
        <ArrowRightIcon
          className={clsx(styles.arrowIcon, {
            [styles.arrowFlipped]: isOpen,
          })}
          data-testid={USERS_AND_GROUPS_SELECT_GROUP_ITEM_ARROW_TESTID}
          size={14}
        />
      </button>
    </Popover>
  );
};
